// US export const API_BASE_URL = 'https://us-central1-layla-5d160.cloudfunctions.net/api/';
export const API_BASE_URL = 'https://asia-east2-layla-5d160.cloudfunctions.net/api/';
export const GOOGLE_API_KEY = 'AIzaSyDOUpOqVmfx1JI3IZBxVAcbxRzn2czT8gI';
export const ACCESS_TOKEN = 'accessToken';
export const LOGGED_USER = 'logged_user';
export const USER_TYPE = 'user_type';
export const SUPPLIER_ID = 'supplier_id';
export const REFRESH_TOKEN = 'refreshToken';

export const getBadge = status => {
    switch (status) {
      case 'Active': return 'success'
      case 'Inactive': return 'secondary'
      case 'Pending': return 'warning'
      case 'Banned': return 'danger'
      case 'Delete': return 'danger'
      default: return 'primary'
    }
}

export const getAvailabilityBadge = status => {
  switch (status) {
    case 'Public': return 'success'
    case 'Hidden': return 'secondary'
    case 'New': return 'warning'
    default: return 'danger'
  }
}