import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './context/PrivateRoute';
import { LOGGED_USER } from './constants';
import './scss/style.scss';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEdit, faArrowLeft,  
  faMinusCircle,
  faShoppingCart,
  faTrashAlt,
  faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

library.add(faEye)
library.add(faEdit)
library.add(faArrowLeft)
library.add(faMinusCircle);
library.add(faShoppingCart);
library.add(faTrashAlt);
library.add(faChevronCircleRight);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));
const Signup = React.lazy(() => import('./views/supplier/signup/Signup'));
const CompleteSignup = React.lazy(() => import('./views/supplier/signup/SignupSuccess'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const GcashSuccess = React.lazy(() => import('./views/pages/landing/GcashSuccess'));
const GcashFailed = React.lazy(() => import('./views/pages/landing/GcashFailed'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: false
    }
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this); 
  }

  loadCurrentUser() {
    this.setState({
      isLoading: false,
      isAuthenticated: true,
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  handleLogin() {
    this.loadCurrentUser();
    this.props.history.push("/dashboard");
  }

  render() {
    let isAuthenticated = localStorage.getItem(LOGGED_USER) !== undefined && 
      localStorage.getItem(LOGGED_USER) !== "" && 
      localStorage.getItem(LOGGED_USER) !== null

    return (
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login onLogin={this.handleLogin} {...props}/>} />
          <Route exact path="/signup" name="Sign Up Page" render={props => <Signup {...props}/>} />
          <Route exact path="/signup-successful" name="Signup Complete" render={props => <CompleteSignup {...props}/>} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
          <Route exact path="/gcash-successful-payment-verification" name="Success Payment" render={props => <GcashSuccess {...props} />} />
          <Route exact path="/gcash-failed-payment" name="Failed Payment" render={props => <GcashFailed {...props} />} />
          {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
          <PrivateRoute path="/" name="Home" component={TheLayout} authenticated={isAuthenticated} 
              render={props => <TheLayout {...props}/>} />
        </Switch>
      </React.Suspense>
    );
  }
}

export default withRouter(App);
